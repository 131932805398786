import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// import Layout from '../components/Layout';
// import SEO from '../components/SEO';
import { BlogIndex } from '../components/Blog';
import Pagination from '../components/Pagination';
import SlicesRenderer from '../components/SlicesRenderer';

const WorkoutListTemplate = ({ data, pageContext, path, location }) => {
  const {
    prismicWorkoutIndex: { data: blogIndex },
    allPrismicWorkout: { edges: blogsData },
  } = data;

  const topComponents = blogIndex.body.filter(
    (slice) => slice.slice_type === 'bem'
  );
  const bottomComponents = blogIndex.body.filter(
    (slice) => slice.slice_type !== 'bem'
  );
  const blogCTAs = blogIndex.body.filter(
    (slice) => slice.slice_type === 'blog_item_cta'
  );

  const { basePath, humanPageNumber, categories } = pageContext;

  const blogs = blogsData.map((blog) => blog.node);

  if (!blogs) return null;

  const normalizedCats = categories.map((cat) => ({
    path: `${basePath}/${cat.uid}`,
    name: cat.document.data.name,
    color: cat.document.data.color,
  }));
  const categoriesList = [
    { name: 'Everything', path: basePath },
    ...normalizedCats,
  ];

  return (
    <>
      {/* <SEO
        pathname={location.pathname}
        title="Guidance | Board Effectiveness Model"
      /> */}
      <SlicesRenderer slices={topComponents} basePath={basePath} />
      <BlogIndex
        data={blogIndex}
        blogs={blogs}
        blogCTAs={blogCTAs}
        basePath={basePath}
        isFirstPage={humanPageNumber === 1}
        categories={categoriesList}
        path={path}
      />
      <Pagination data={pageContext} />
      <SlicesRenderer slices={bottomComponents} />
    </>
  );
};

WorkoutListTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WorkoutListTemplate;

export const data = graphql`
  query($skip: Int!, $limit: Int!, $lang: String!) {
    prismicWorkoutIndex(lang: { eq: $lang }) {
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicWorkoutIndexBodyCta {
            id
            slice_type
            primary {
              action_label
              heading {
                html
              }
              subheading
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicWorkoutIndexBodyBlogItemCta {
            id
            slice_type
            primary {
              item_title
              color_scheme
              action_label
              action_url {
                target
                url
                type
                uid
              }
            }
          }
          ... on PrismicWorkoutIndexBodyBem {
            id
            slice_type
            primary {
              heading {
                text
                html
              }
              subheading
              info_title
              what_title
              how_title
              info_content {
                html
              }
            }
            items {
              group
              category {
                uid
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      color
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicWorkout(
      filter: { lang: { eq: $lang } }
      sort: { fields: last_publication_date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            excerpt
            category {
              uid
              document {
                ... on PrismicCategory {
                  data {
                    color
                    name
                  }
                }
              }
            }
            featured_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 300, fit: COVER) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
